<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-5">
                <label class="form-col-label mb-1 control-label required">
                    {{ $t("monitoringQuestion.questionType") }}
                </label>
                <select class="form-control" v-model="model.answer_format">
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                    >
                        {{ $t("select") }}
                    </option>
                    <option
                        v-for="(option, index) in displayFormats"
                        :key="index"
                        :value="option.display_format"
                    >
                        {{ option.display_format }}
                    </option>
                </select>
            </div>
            <div class="col-md-5">
                <label class="form-col-label mb-1 control-label required">
                    {{ $t("monitoringQuestion.questionCategory") }}
                </label>
                <select class="form-control" v-model="model.question_cat_id">
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                    >
                        {{ $t("select") }}
                    </option>
                    <option
                        v-for="(option, index) in questionCategories"
                        :key="index"
                        :value="option.question_cat_id"
                    >
                        {{ option.question_cat_name_en }}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
                <label class="form-col-label mb-1 control-label">
                    {{ $t("monitoringQuestion.displayOrder") }}
                </label>
                <input
                    type="text"
                    v-model="model.display_order"
                    class="form-control mb-1"
                    :placeholder="$t('monitoringQuestion.displayOrder')"
                />
            </div>
        </div>
        <div class="row mb-3">
            <label class="form-col-label mb-1 control-label">
                {{ $t("monitoringQuestion.questionKh") }}
            </label>
            <ckeditor
                v-model="model.question_kh"
                :config="{ height: 50 }"
            ></ckeditor>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <input
                    type="text"
                    v-model="model.question_short_kh"
                    class="form-control mb-1"
                    :placeholder="$t('monitoringQuestion.questionShortKh')"
                />
            </div>
        </div>
        <div class="row mb-3">
            <label class="form-col-label mb-1 control-label required">
                {{ $t("monitoringQuestion.questionEn") }}
            </label>
            <ckeditor
                v-model="model.question_en"
                :config="{ height: 50 }"
            ></ckeditor>
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <input
                    type="text"
                    v-model="model.question_short_en"
                    class="form-control mb-1"
                    :placeholder="$t('monitoringQuestion.questionShortEn')"
                />
            </div>
        </div>
        <div class="row mb-3">
            <multiple-choice-radio
                ref="multiple_choice_radio"
                :validate="errors"
                v-model="model"
            />
        </div>
        <div class="row mb-3">
            <div class="col-md-12">
                <label class="form-col-label mb-1 control-label">
                    {{ $t("monitoringQuestion.remark") }}
                </label>
                <textarea
                    v-model="model.remark"
                    rows="2"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('remark') }"
                ></textarea>
                <div class="invalid-feedback" v-if="errors.has('remark')">
                    {{ errors.first("remark") }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { mapState } from "vuex/dist/vuex.common.js";
import { mapActions } from "vuex/dist/vuex.common.js";
import Vue from "vue";
import CKEditor from "ckeditor4-vue";
import MultipleChoiceRadio from "./multiple-choice-radio.vue";
Vue.use(CKEditor);
export default {
    name: "questionInfo",
    props: ["value", "validate"],
    components: {
        MultipleChoiceRadio
    },
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/monitoringQuestion", [
            "edit_data",
            "displayFormats",
            "questionCategories"
        ])
    },
    methods: {
        ...mapActions("creditOperation/monitoringQuestion", [
            "getDisplayFormat",
            "getQuestionCategory"
        ])
    }
};
</script>
<style>
.cke_notifications_area {
    display: none !important;
}
.cke_inner .cke_reset {
    border: 1px solid #ced4da;
}
</style>
